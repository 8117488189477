import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import {} from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './supply-partner.reducer';

export const SupplyPartnerDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const supplyPartnerEntity = useAppSelector(state => state.supplyPartner.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="supplyPartnerDetailsHeading">Supply Partner</h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">ID</span>
          </dt>
          <dd>{supplyPartnerEntity.id}</dd>
          <dt>
            <span id="supplyPartnerName">Supply Partner Name</span>
          </dt>
          <dd>{supplyPartnerEntity.supplyPartnerName}</dd>
          <dt>
            <span id="domain">Domain</span>
          </dt>
          <dd>{supplyPartnerEntity.domain}</dd>
          <dt>
            <span id="contactName">Contact Name</span>
          </dt>
          <dd>{supplyPartnerEntity.contactName}</dd>
          <dt>
            <span id="contactEmail">Contact Email</span>
          </dt>
          <dd>{supplyPartnerEntity.contactEmail}</dd>
          <dt>
            <span id="supplyPartnerDiscription">Supply Partner Discription</span>
          </dt>
          <dd>{supplyPartnerEntity.supplyPartnerDiscription}</dd>
          <dt>
            <span id="publicNameForSupplyPartner">Public Name For Supply Partner</span>
          </dt>
          <dd>{supplyPartnerEntity.publicNameForSupplyPartner}</dd>
          <dt>
            <span id="sellerType">Seller Type</span>
          </dt>
          <dd>{supplyPartnerEntity.sellerType}</dd>
          <dt>
            <span id="isConfidential">Is Confidential</span>
          </dt>
          <dd>{supplyPartnerEntity.isConfidential ? 'true' : 'false'}</dd>
          <dt>
            <span id="impressionTrackingMethod">Impression Tracking Method</span>
          </dt>
          <dd>{supplyPartnerEntity.impressionTrackingMethod}</dd>
        </dl>
        <Button tag={Link} to="/supply-partner" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/supply-partner/${supplyPartnerEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

export default SupplyPartnerDetail;

import { IUiReportFilter } from 'app/shared/model/ui-report-filter.model';

export interface IScheduleReport {
  id?: number;
  receiverEmail?: string | null;
  scheduleType?: string | null;
  timeStamp?: string | null;
  isEnabled?: boolean | null;
  report?: IUiReportFilter | null;
}

export const defaultValue: Readonly<IScheduleReport> = {
  isEnabled: false,
};

export enum ImpType {
  ALL = 'ALL',

  BANNER = 'BANNER',

  VIDEO = 'VIDEO',

  AUDIO = 'AUDIO',

  NATIVE = 'NATIVE',
}

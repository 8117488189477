export interface IUiReportFilter {
  id?: number;
  reportType?: string | null;
  reportName?: string | null;
  filters?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  dateRange?: string | null;
  isEnabled?: boolean | null;
}

export const defaultValue: Readonly<IUiReportFilter> = {
  isEnabled: false,
};

import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { createEntity, getEntity, reset, updateEntity } from './ui-report-filter.reducer';

export const UiReportFilterUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const uiReportFilterEntity = useAppSelector(state => state.uiReportFilter.entity);
  const loading = useAppSelector(state => state.uiReportFilter.loading);
  const updating = useAppSelector(state => state.uiReportFilter.updating);
  const updateSuccess = useAppSelector(state => state.uiReportFilter.updateSuccess);

  const handleClose = () => {
    navigate('/ui-report-filter');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }

    const entity = {
      ...uiReportFilterEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...uiReportFilterEntity,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="bidfilter3UiApp.uiReportFilter.home.createOrEditLabel" data-cy="UiReportFilterCreateUpdateHeading">
            Create or edit a Ui Report Filter
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField name="id" required readOnly id="ui-report-filter-id" label="ID" validate={{ required: true }} />
              ) : null}
              <ValidatedField label="Report Type" id="ui-report-filter-reportType" name="reportType" data-cy="reportType" type="text" />
              <ValidatedField label="Report Name" id="ui-report-filter-reportName" name="reportName" data-cy="reportName" type="text" />
              <ValidatedField label="Filters" id="ui-report-filter-filters" name="filters" data-cy="filters" type="text" />
              <ValidatedField label="Start Date" id="ui-report-filter-startDate" name="startDate" data-cy="startDate" type="text" />
              <ValidatedField label="End Date" id="ui-report-filter-endDate" name="endDate" data-cy="endDate" type="text" />
              <ValidatedField label="Date Range" id="ui-report-filter-dateRange" name="dateRange" data-cy="dateRange" type="text" />
              <ValidatedField
                label="Is Enabled"
                id="ui-report-filter-isEnabled"
                name="isEnabled"
                data-cy="isEnabled"
                check
                type="checkbox"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/ui-report-filter" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default UiReportFilterUpdate;

import { ImpressionTrackingMethod } from 'app/shared/model/enumerations/impression-tracking-method.model';

export interface ISupplyPartner {
  id?: number;
  supplyPartnerName?: string | null;
  domain?: string | null;
  contactName?: string | null;
  contactEmail?: string | null;
  supplyPartnerDiscription?: string | null;
  publicNameForSupplyPartner?: string | null;
  sellerType?: string | null;
  isConfidential?: boolean | null;
  impressionTrackingMethod?: keyof typeof ImpressionTrackingMethod | null;
}

export const defaultValue: Readonly<ISupplyPartner> = {
  isConfidential: false,
};

import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getDemandPartners } from 'app/entities/demand-partner/demand-partner.reducer';
import { ImpType } from 'app/shared/model/enumerations/imp-type.model';
import { DailyBudgetPacing } from 'app/shared/model/enumerations/daily-budget-pacing.model';
import { EndpointDatacenterLocation } from 'app/shared/model/enumerations/endpoint-datacenter-location.model';
import { AuctionType } from 'app/shared/model/enumerations/auction-type.model';
import { createEntity, getEntity, reset, updateEntity } from './demand.reducer';

export const DemandUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const demandPartners = useAppSelector(state => state.demandPartner.entities);
  const demandEntity = useAppSelector(state => state.demand.entity);
  const loading = useAppSelector(state => state.demand.loading);
  const updating = useAppSelector(state => state.demand.updating);
  const updateSuccess = useAppSelector(state => state.demand.updateSuccess);
  const impTypeValues = Object.keys(ImpType);
  const dailyBudgetPacingValues = Object.keys(DailyBudgetPacing);
  const endpointDatacenterLocationValues = Object.keys(EndpointDatacenterLocation);
  const auctionTypeValues = Object.keys(AuctionType);

  const handleClose = () => {
    navigate(`/demand${location.search}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getDemandPartners({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...demandEntity,
      ...values,
      demandPartner: demandPartners.find(it => it.id.toString() === values.demandPartner?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          impType: 'ALL',
          dailyBudgetPacing: 'AS_SOON_AS_POSSIBLE',
          endpointDatacenterLocation: 'US_EAST_OHIO',
          auctionType: 'First_Price',
          ...demandEntity,
          demandPartner: demandEntity?.demandPartner?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="bidfilter3UiApp.demand.home.createOrEditLabel" data-cy="DemandCreateUpdateHeading">
            Create or edit a Demand
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? <ValidatedField name="id" required readOnly id="demand-id" label="ID" validate={{ required: true }} /> : null}
              <ValidatedField label="Name" id="demand-name" name="name" data-cy="name" type="text" />
              <ValidatedField label="End Point" id="demand-endPoint" name="endPoint" data-cy="endPoint" type="text" />
              <ValidatedField label="Type" id="demand-type" name="type" data-cy="type" type="text" />
              <ValidatedField label="Demand Setting" id="demand-demandSetting" name="demandSetting" data-cy="demandSetting" type="text" />
              <ValidatedField label="Margin" id="demand-margin" name="margin" data-cy="margin" type="text" />
              <ValidatedField
                label="R Compression"
                id="demand-rCompression"
                name="rCompression"
                data-cy="rCompression"
                check
                type="checkbox"
              />
              <ValidatedField label="Domain" id="demand-domain" name="domain" data-cy="domain" type="text" />
              <ValidatedField label="Service Fee" id="demand-serviceFee" name="serviceFee" data-cy="serviceFee" type="text" />
              <ValidatedField label="Qps" id="demand-qps" name="qps" data-cy="qps" type="text" />
              <ValidatedField label="Is Enabled" id="demand-isEnabled" name="isEnabled" data-cy="isEnabled" check type="checkbox" />
              <ValidatedField
                label="Max Schain Nodes"
                id="demand-maxSchainNodes"
                name="maxSchainNodes"
                data-cy="maxSchainNodes"
                type="text"
              />
              <ValidatedField
                label="Bundle Setting Key"
                id="demand-bundleSettingKey"
                name="bundleSettingKey"
                data-cy="bundleSettingKey"
                type="text"
              />
              <ValidatedField label="Imp Type" id="demand-impType" name="impType" data-cy="impType" type="select">
                {impTypeValues.map(impType => (
                  <option value={impType} key={impType}>
                    {impType}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label="Min Tmax Required"
                id="demand-minTmaxRequired"
                name="minTmaxRequired"
                data-cy="minTmaxRequired"
                type="text"
              />
              <ValidatedField
                label="Demand Description"
                id="demand-demandDescription"
                name="demandDescription"
                data-cy="demandDescription"
                type="text"
              />
              <ValidatedField label="Daily Budget" id="demand-dailyBudget" name="dailyBudget" data-cy="dailyBudget" type="text" />
              <ValidatedField label="Total Budget" id="demand-totalBudget" name="totalBudget" data-cy="totalBudget" type="text" />
              <ValidatedField
                label="Daily Budget Pacing"
                id="demand-dailyBudgetPacing"
                name="dailyBudgetPacing"
                data-cy="dailyBudgetPacing"
                type="select"
              >
                {dailyBudgetPacingValues.map(dailyBudgetPacing => (
                  <option value={dailyBudgetPacing} key={dailyBudgetPacing}>
                    {dailyBudgetPacing}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label="Endpoint Datacenter Location"
                id="demand-endpointDatacenterLocation"
                name="endpointDatacenterLocation"
                data-cy="endpointDatacenterLocation"
                type="select"
              >
                {endpointDatacenterLocationValues.map(endpointDatacenterLocation => (
                  <option value={endpointDatacenterLocation} key={endpointDatacenterLocation}>
                    {endpointDatacenterLocation}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField label="Auction Type" id="demand-auctionType" name="auctionType" data-cy="auctionType" type="select">
                {auctionTypeValues.map(auctionType => (
                  <option value={auctionType} key={auctionType}>
                    {auctionType}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField label="Is Archived" id="demand-isArchived" name="isArchived" data-cy="isArchived" check type="checkbox" />
              <ValidatedField id="demand-demandPartner" name="demandPartner" data-cy="demandPartner" label="Demand Partner" type="select">
                <option value="" key="0" />
                {demandPartners
                  ? demandPartners.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.demandPartnerName}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/demand" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default DemandUpdate;

import { IUser } from 'app/shared/model/user.model';

export interface IUserAccount {
  id?: number;
  name?: string | null;
  country?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  zipcode?: number | null;
  state?: string | null;
  companyLogo?: string | null;
  users?: IUser[] | null;
}

export const defaultValue: Readonly<IUserAccount> = {};

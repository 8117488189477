import { IDemandPartner } from 'app/shared/model/demand-partner.model';
import { ImpType } from 'app/shared/model/enumerations/imp-type.model';
import { DailyBudgetPacing } from 'app/shared/model/enumerations/daily-budget-pacing.model';
import { EndpointDatacenterLocation } from 'app/shared/model/enumerations/endpoint-datacenter-location.model';
import { AuctionType } from 'app/shared/model/enumerations/auction-type.model';

export interface IDemand {
  id?: number;
  name?: string | null;
  endPoint?: string | null;
  type?: string | null;
  demandSetting?: string | null;
  margin?: number | null;
  rCompression?: boolean | null;
  domain?: string | null;
  serviceFee?: number | null;
  qps?: number | null;
  isEnabled?: boolean | null;
  maxSchainNodes?: number | null;
  bundleSettingKey?: string | null;
  impType?: keyof typeof ImpType | null;
  minTmaxRequired?: number | null;
  demandDescription?: string | null;
  dailyBudget?: number | null;
  totalBudget?: number | null;
  dailyBudgetPacing?: keyof typeof DailyBudgetPacing | null;
  endpointDatacenterLocation?: keyof typeof EndpointDatacenterLocation | null;
  auctionType?: keyof typeof AuctionType | null;
  isArchived?: boolean | null;
  demandPartner?: IDemandPartner | null;
}

export const defaultValue: Readonly<IDemand> = {
  rCompression: false,
  isEnabled: false,
  isArchived: false,
};

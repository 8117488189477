import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { Status } from 'app/shared/model/enumerations/status.model';
import { createEntity, getEntity, reset, updateEntity } from './demand-app-approval-status.reducer';

export const DemandAppApprovalStatusUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const demandAppApprovalStatusEntity = useAppSelector(state => state.demandAppApprovalStatus.entity);
  const loading = useAppSelector(state => state.demandAppApprovalStatus.loading);
  const updating = useAppSelector(state => state.demandAppApprovalStatus.updating);
  const updateSuccess = useAppSelector(state => state.demandAppApprovalStatus.updateSuccess);
  const statusValues = Object.keys(Status);

  const handleClose = () => {
    navigate(`/demand-app-approval-status${location.search}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.demandPartnerId !== undefined && typeof values.demandPartnerId !== 'number') {
      values.demandPartnerId = Number(values.demandPartnerId);
    }
    values.dateUpdated = convertDateTimeToServer(values.dateUpdated);

    const entity = {
      ...demandAppApprovalStatusEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          dateUpdated: displayDefaultDateTime(),
        }
      : {
          status: 'APPROVED',
          ...demandAppApprovalStatusEntity,
          dateUpdated: convertDateTimeFromServer(demandAppApprovalStatusEntity.dateUpdated),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="bidfilter3UiApp.demandAppApprovalStatus.home.createOrEditLabel" data-cy="DemandAppApprovalStatusCreateUpdateHeading">
            Create or edit a Demand App Approval Status
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField name="id" required readOnly id="demand-app-approval-status-id" label="Id" validate={{ required: true }} />
              ) : null}
              <ValidatedField
                label="Demand Partner Id"
                id="demand-app-approval-status-demandPartnerId"
                name="demandPartnerId"
                data-cy="demandPartnerId"
                type="text"
              />
              <ValidatedField
                label="App Bundle"
                id="demand-app-approval-status-appBundle"
                name="appBundle"
                data-cy="appBundle"
                type="text"
              />
              <ValidatedField label="Status" id="demand-app-approval-status-status" name="status" data-cy="status" type="select">
                {statusValues.map(status => (
                  <option value={status} key={status}>
                    {status}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label="Line Inserted"
                id="demand-app-approval-status-lineInserted"
                name="lineInserted"
                data-cy="lineInserted"
                type="date"
              />
              <ValidatedField
                label="Date Updated"
                id="demand-app-approval-status-dateUpdated"
                name="dateUpdated"
                data-cy="dateUpdated"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/demand-app-approval-status" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default DemandAppApprovalStatusUpdate;

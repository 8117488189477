export interface ITimeZone {
  id?: number;
  gmt?: string | null;
  dst?: number | null;
  code?: string | null;
  name?: string | null;
  offset?: number | null;
}

export const defaultValue: Readonly<ITimeZone> = {};

import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import {} from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './demand.reducer';

export const DemandDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const demandEntity = useAppSelector(state => state.demand.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="demandDetailsHeading">Demand</h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">ID</span>
          </dt>
          <dd>{demandEntity.id}</dd>
          <dt>
            <span id="name">Name</span>
          </dt>
          <dd>{demandEntity.name}</dd>
          <dt>
            <span id="endPoint">End Point</span>
          </dt>
          <dd>{demandEntity.endPoint}</dd>
          <dt>
            <span id="type">Type</span>
          </dt>
          <dd>{demandEntity.type}</dd>
          <dt>
            <span id="demandSetting">Demand Setting</span>
          </dt>
          <dd>{demandEntity.demandSetting}</dd>
          <dt>
            <span id="margin">Margin</span>
          </dt>
          <dd>{demandEntity.margin}</dd>
          <dt>
            <span id="rCompression">R Compression</span>
          </dt>
          <dd>{demandEntity.rCompression ? 'true' : 'false'}</dd>
          <dt>
            <span id="domain">Domain</span>
          </dt>
          <dd>{demandEntity.domain}</dd>
          <dt>
            <span id="serviceFee">Service Fee</span>
          </dt>
          <dd>{demandEntity.serviceFee}</dd>
          <dt>
            <span id="qps">Qps</span>
          </dt>
          <dd>{demandEntity.qps}</dd>
          <dt>
            <span id="isEnabled">Is Enabled</span>
          </dt>
          <dd>{demandEntity.isEnabled ? 'true' : 'false'}</dd>
          <dt>
            <span id="maxSchainNodes">Max Schain Nodes</span>
          </dt>
          <dd>{demandEntity.maxSchainNodes}</dd>
          <dt>
            <span id="bundleSettingKey">Bundle Setting Key</span>
          </dt>
          <dd>{demandEntity.bundleSettingKey}</dd>
          <dt>
            <span id="impType">Imp Type</span>
          </dt>
          <dd>{demandEntity.impType}</dd>
          <dt>
            <span id="minTmaxRequired">Min Tmax Required</span>
          </dt>
          <dd>{demandEntity.minTmaxRequired}</dd>
          <dt>
            <span id="demandDescription">Demand Description</span>
          </dt>
          <dd>{demandEntity.demandDescription}</dd>
          <dt>
            <span id="dailyBudget">Daily Budget</span>
          </dt>
          <dd>{demandEntity.dailyBudget}</dd>
          <dt>
            <span id="totalBudget">Total Budget</span>
          </dt>
          <dd>{demandEntity.totalBudget}</dd>
          <dt>
            <span id="dailyBudgetPacing">Daily Budget Pacing</span>
          </dt>
          <dd>{demandEntity.dailyBudgetPacing}</dd>
          <dt>
            <span id="endpointDatacenterLocation">Endpoint Datacenter Location</span>
          </dt>
          <dd>{demandEntity.endpointDatacenterLocation}</dd>
          <dt>
            <span id="auctionType">Auction Type</span>
          </dt>
          <dd>{demandEntity.auctionType}</dd>
          <dt>
            <span id="isArchived">Is Archived</span>
          </dt>
          <dd>{demandEntity.isArchived ? 'true' : 'false'}</dd>
          <dt>Demand Partner</dt>
          <dd>{demandEntity.demandPartner ? demandEntity.demandPartner.demandPartnerName : ''}</dd>
        </dl>
        <Button tag={Link} to="/demand" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/demand/${demandEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

export default DemandDetail;

import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { getEntities as getSupplyPartners } from 'app/entities/supply-partner/supply-partner.reducer';
import { getEntities as getSupplyToDemandFilters } from 'app/entities/supply-to-demand-filter/supply-to-demand-filter.reducer';
import { Datacenter } from 'app/shared/model/enumerations/datacenter.model';
import { createEntity, getEntity, reset, updateEntity } from './supply.reducer';

export const SupplyUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const users = useAppSelector(state => state.userManagement.users);
  const supplyPartners = useAppSelector(state => state.supplyPartner.entities);
  const supplyToDemandFilters = useAppSelector(state => state.supplyToDemandFilter.entities);
  const supplyEntity = useAppSelector(state => state.supply.entity);
  const loading = useAppSelector(state => state.supply.loading);
  const updating = useAppSelector(state => state.supply.updating);
  const updateSuccess = useAppSelector(state => state.supply.updateSuccess);
  const datacenterValues = Object.keys(Datacenter);

  const handleClose = () => {
    navigate(`/supply${location.search}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getUsers({}));
    dispatch(getSupplyPartners({}));
    dispatch(getSupplyToDemandFilters({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...supplyEntity,
      ...values,
      user: users.find(it => it.id.toString() === values.user?.toString()),
      supplyPartner: supplyPartners.find(it => it.id.toString() === values.supplyPartner?.toString()),
      stodfs: mapIdList(values.stodfs),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          datacenter: 'US_East',
          ...supplyEntity,
          user: supplyEntity?.user?.id,
          supplyPartner: supplyEntity?.supplyPartner?.id,
          stodfs: supplyEntity?.stodfs?.map(e => e.id.toString()),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="bidfilter3UiApp.supply.home.createOrEditLabel" data-cy="SupplyCreateUpdateHeading">
            Create or edit a Supply
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? <ValidatedField name="id" required readOnly id="supply-id" label="ID" validate={{ required: true }} /> : null}
              <ValidatedField label="Name" id="supply-name" name="name" data-cy="name" type="text" />
              <ValidatedField label="Is Enabled" id="supply-isEnabled" name="isEnabled" data-cy="isEnabled" check type="checkbox" />
              <ValidatedField label="Supply Domain" id="supply-supplyDomain" name="supplyDomain" data-cy="supplyDomain" type="text" />
              <ValidatedField
                label="Is Third Party Supply"
                id="supply-isThirdPartySupply"
                name="isThirdPartySupply"
                data-cy="isThirdPartySupply"
                check
                type="checkbox"
              />
              <ValidatedField label="Type" id="supply-type" name="type" data-cy="type" type="text" />
              <ValidatedField
                label="Request Processing Time"
                id="supply-requestProcessingTime"
                name="requestProcessingTime"
                data-cy="requestProcessingTime"
                type="text"
              />
              <ValidatedField
                label="Default Supply Tmax"
                id="supply-defaultSupplyTmax"
                name="defaultSupplyTmax"
                data-cy="defaultSupplyTmax"
                type="text"
              />
              <ValidatedField
                label="Blocked Creative Attributes"
                id="supply-blockedCreativeAttributes"
                name="blockedCreativeAttributes"
                data-cy="blockedCreativeAttributes"
                type="text"
              />
              <ValidatedField
                label="Blocked Categories"
                id="supply-blockedCategories"
                name="blockedCategories"
                data-cy="blockedCategories"
                type="text"
              />
              <ValidatedField
                label="Blocked Banner Ad Types"
                id="supply-blockedBannerAdTypes"
                name="blockedBannerAdTypes"
                data-cy="blockedBannerAdTypes"
                type="text"
              />
              <ValidatedField label="Datacenter" id="supply-datacenter" name="datacenter" data-cy="datacenter" type="select">
                {datacenterValues.map(datacenter => (
                  <option value={datacenter} key={datacenter}>
                    {datacenter}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label="Supply Description"
                id="supply-supplyDescription"
                name="supplyDescription"
                data-cy="supplyDescription"
                type="text"
              />
              <ValidatedField label="Is Archived" id="supply-isArchived" name="isArchived" data-cy="isArchived" check type="checkbox" />
              <ValidatedField
                label="Is Max Mind Enabled"
                id="supply-isMaxMindEnabled"
                name="isMaxMindEnabled"
                data-cy="isMaxMindEnabled"
                check
                type="checkbox"
              />
              <ValidatedField id="supply-user" name="user" data-cy="user" label="User" type="select">
                <option value="" key="0" />
                {users
                  ? users.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.login}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField id="supply-supplyPartner" name="supplyPartner" data-cy="supplyPartner" label="Supply Partner" type="select">
                <option value="" key="0" />
                {supplyPartners
                  ? supplyPartners.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.supplyPartnerName}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField label="Stodf" id="supply-stodf" data-cy="stodf" type="select" multiple name="stodfs">
                <option value="" key="0" />
                {supplyToDemandFilters
                  ? supplyToDemandFilters.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/supply" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default SupplyUpdate;

import React from 'react';

import MenuItem from 'app/shared/layout/menus/menu-item';

const EntitiesMenu = () => {
  return (
    <>
      {/* prettier-ignore */}
      <MenuItem icon="asterisk" to="/supply-partner?page=1&sort=id,desc">
        Supply Partner
      </MenuItem>
      <MenuItem icon="asterisk" to="/demand-partner?page=1&sort=id,desc">
        Demand Partner
      </MenuItem>
      <MenuItem icon="asterisk" to="/supply?page=1&sort=id,desc">
        Supply
      </MenuItem>
      <MenuItem icon="asterisk" to="/demand?page=1&sort=id,desc">
        Demand
      </MenuItem>
      <MenuItem icon="asterisk" to="/supply-to-demand-filter?page=1&sort=id,desc">
        Supply To Demand Filter
      </MenuItem>
      <MenuItem icon="asterisk" to="/content-taxonomy">
        Content Taxonomy
      </MenuItem>
      <MenuItem icon="asterisk" to="/time-zone">
        Time Zone
      </MenuItem>
      <MenuItem icon="asterisk" to="/ui-report-filter">
        Ui Report Filter
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-account">
        User Account
      </MenuItem>
      <MenuItem icon="asterisk" to="/schedule-report">
        Schedule Report
      </MenuItem>
      <MenuItem icon="asterisk" to="/demand-app-approval-status">
        Demand App Approval Status
      </MenuItem>
      <MenuItem icon="asterisk" to="/notification">
        Notification
      </MenuItem>
      {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
    </>
  );
};

export default EntitiesMenu;

import { DailyBudgetPacing } from 'app/shared/model/enumerations/daily-budget-pacing.model';
import { ImpressionTrackingMethod } from 'app/shared/model/enumerations/impression-tracking-method.model';

export interface IDemandPartner {
  id?: number;
  demandPartnerName?: string | null;
  contactName?: string | null;
  contactEmail?: string | null;
  demandPartnerDescription?: string | null;
  dailyBudget?: number | null;
  totalBudget?: number | null;
  dailyBudgetPacing?: keyof typeof DailyBudgetPacing | null;
  impressionTrackingMethod?: keyof typeof ImpressionTrackingMethod | null;
}

export const defaultValue: Readonly<IDemandPartner> = {};

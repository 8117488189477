import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getDemands } from 'app/entities/demand/demand.reducer';
import { getEntities as getSupplies } from 'app/entities/supply/supply.reducer';
import { createEntity, getEntity, reset, updateEntity } from './supply-to-demand-filter.reducer';

export const SupplyToDemandFilterUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const demands = useAppSelector(state => state.demand.entities);
  const supplies = useAppSelector(state => state.supply.entities);
  const supplyToDemandFilterEntity = useAppSelector(state => state.supplyToDemandFilter.entity);
  const loading = useAppSelector(state => state.supplyToDemandFilter.loading);
  const updating = useAppSelector(state => state.supplyToDemandFilter.updating);
  const updateSuccess = useAppSelector(state => state.supplyToDemandFilter.updateSuccess);

  const handleClose = () => {
    navigate(`/supply-to-demand-filter${location.search}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getDemands({}));
    dispatch(getSupplies({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...supplyToDemandFilterEntity,
      ...values,
      demand: demands.find(it => it.id.toString() === values.demand?.toString()),
      supplies: mapIdList(values.supplies),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...supplyToDemandFilterEntity,
          demand: supplyToDemandFilterEntity?.demand?.id,
          supplies: supplyToDemandFilterEntity?.supplies?.map(e => e.id.toString()),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="bidfilter3UiApp.supplyToDemandFilter.home.createOrEditLabel" data-cy="SupplyToDemandFilterCreateUpdateHeading">
            Create or edit a Supply To Demand Filter
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField name="id" required readOnly id="supply-to-demand-filter-id" label="ID" validate={{ required: true }} />
              ) : null}
              <ValidatedField label="Name" id="supply-to-demand-filter-name" name="name" data-cy="name" type="text" />
              <ValidatedField
                label="Filter Expression"
                id="supply-to-demand-filter-filterExpression"
                name="filterExpression"
                data-cy="filterExpression"
                type="text"
              />
              <ValidatedField label="Json Merge" id="supply-to-demand-filter-jsonMerge" name="jsonMerge" data-cy="jsonMerge" type="text" />
              <ValidatedField label="Margin" id="supply-to-demand-filter-margin" name="margin" data-cy="margin" type="text" />
              <ValidatedField
                label="Vast Setting"
                id="supply-to-demand-filter-vastSetting"
                name="vastSetting"
                data-cy="vastSetting"
                type="text"
              />
              <ValidatedField label="Qps" id="supply-to-demand-filter-qps" name="qps" data-cy="qps" type="text" />
              <ValidatedField
                label="Is Enabled"
                id="supply-to-demand-filter-isEnabled"
                name="isEnabled"
                data-cy="isEnabled"
                check
                type="checkbox"
              />
              <ValidatedField
                label="Is Seller Schain Required"
                id="supply-to-demand-filter-isSellerSchainRequired"
                name="isSellerSchainRequired"
                data-cy="isSellerSchainRequired"
                check
                type="checkbox"
              />
              <ValidatedField
                label="Is Seller Schain Override"
                id="supply-to-demand-filter-isSellerSchainOverride"
                name="isSellerSchainOverride"
                data-cy="isSellerSchainOverride"
                check
                type="checkbox"
              />
              <ValidatedField label="Schain" id="supply-to-demand-filter-schain" name="schain" data-cy="schain" type="text" />
              <ValidatedField label="Comment" id="supply-to-demand-filter-comment" name="comment" data-cy="comment" type="text" />
              <ValidatedField label="Bcat" id="supply-to-demand-filter-bcat" name="bcat" data-cy="bcat" type="text" />
              <ValidatedField label="Badv" id="supply-to-demand-filter-badv" name="badv" data-cy="badv" type="text" />
              <ValidatedField
                label="Is Archived"
                id="supply-to-demand-filter-isArchived"
                name="isArchived"
                data-cy="isArchived"
                check
                type="checkbox"
              />
              <ValidatedField id="supply-to-demand-filter-demand" name="demand" data-cy="demand" label="Demand" type="select">
                <option value="" key="0" />
                {demands
                  ? demands.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField label="Supply" id="supply-to-demand-filter-supply" data-cy="supply" type="select" multiple name="supplies">
                <option value="" key="0" />
                {supplies
                  ? supplies.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/supply-to-demand-filter" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default SupplyToDemandFilterUpdate;

import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import {} from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './user-account.reducer';

export const UserAccountDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const userAccountEntity = useAppSelector(state => state.userAccount.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="userAccountDetailsHeading">User Account</h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">ID</span>
          </dt>
          <dd>{userAccountEntity.id}</dd>
          <dt>
            <span id="name">Name</span>
          </dt>
          <dd>{userAccountEntity.name}</dd>
          <dt>
            <span id="country">Country</span>
          </dt>
          <dd>{userAccountEntity.country}</dd>
          <dt>
            <span id="addressLine1">Address Line 1</span>
          </dt>
          <dd>{userAccountEntity.addressLine1}</dd>
          <dt>
            <span id="addressLine2">Address Line 2</span>
          </dt>
          <dd>{userAccountEntity.addressLine2}</dd>
          <dt>
            <span id="city">City</span>
          </dt>
          <dd>{userAccountEntity.city}</dd>
          <dt>
            <span id="zipcode">Zipcode</span>
          </dt>
          <dd>{userAccountEntity.zipcode}</dd>
          <dt>
            <span id="state">State</span>
          </dt>
          <dd>{userAccountEntity.state}</dd>
          <dt>
            <span id="companyLogo">Company Logo</span>
          </dt>
          <dd>{userAccountEntity.companyLogo}</dd>
          <dt>User</dt>
          <dd>
            {userAccountEntity.users
              ? userAccountEntity.users.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.login}</a>
                    {userAccountEntity.users && i === userAccountEntity.users.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
        </dl>
        <Button tag={Link} to="/user-account" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/user-account/${userAccountEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

export default UserAccountDetail;

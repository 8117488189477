import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { DailyBudgetPacing } from 'app/shared/model/enumerations/daily-budget-pacing.model';
import { ImpressionTrackingMethod } from 'app/shared/model/enumerations/impression-tracking-method.model';
import { createEntity, getEntity, reset, updateEntity } from './demand-partner.reducer';

export const DemandPartnerUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const demandPartnerEntity = useAppSelector(state => state.demandPartner.entity);
  const loading = useAppSelector(state => state.demandPartner.loading);
  const updating = useAppSelector(state => state.demandPartner.updating);
  const updateSuccess = useAppSelector(state => state.demandPartner.updateSuccess);
  const dailyBudgetPacingValues = Object.keys(DailyBudgetPacing);
  const impressionTrackingMethodValues = Object.keys(ImpressionTrackingMethod);

  const handleClose = () => {
    navigate(`/demand-partner${location.search}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.dailyBudget !== undefined && typeof values.dailyBudget !== 'number') {
      values.dailyBudget = Number(values.dailyBudget);
    }
    if (values.totalBudget !== undefined && typeof values.totalBudget !== 'number') {
      values.totalBudget = Number(values.totalBudget);
    }

    const entity = {
      ...demandPartnerEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          dailyBudgetPacing: 'AS_SOON_AS_POSSIBLE',
          impressionTrackingMethod: 'BURL',
          ...demandPartnerEntity,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="bidfilter3UiApp.demandPartner.home.createOrEditLabel" data-cy="DemandPartnerCreateUpdateHeading">
            Create or edit a Demand Partner
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField name="id" required readOnly id="demand-partner-id" label="ID" validate={{ required: true }} />
              ) : null}
              <ValidatedField
                label="Demand Partner Name"
                id="demand-partner-demandPartnerName"
                name="demandPartnerName"
                data-cy="demandPartnerName"
                type="text"
              />
              <ValidatedField label="Contact Name" id="demand-partner-contactName" name="contactName" data-cy="contactName" type="text" />
              <ValidatedField
                label="Contact Email"
                id="demand-partner-contactEmail"
                name="contactEmail"
                data-cy="contactEmail"
                type="text"
              />
              <ValidatedField
                label="Demand Partner Description"
                id="demand-partner-demandPartnerDescription"
                name="demandPartnerDescription"
                data-cy="demandPartnerDescription"
                type="text"
              />
              <ValidatedField label="Daily Budget" id="demand-partner-dailyBudget" name="dailyBudget" data-cy="dailyBudget" type="text" />
              <ValidatedField label="Total Budget" id="demand-partner-totalBudget" name="totalBudget" data-cy="totalBudget" type="text" />
              <ValidatedField
                label="Daily Budget Pacing"
                id="demand-partner-dailyBudgetPacing"
                name="dailyBudgetPacing"
                data-cy="dailyBudgetPacing"
                type="select"
              >
                {dailyBudgetPacingValues.map(dailyBudgetPacing => (
                  <option value={dailyBudgetPacing} key={dailyBudgetPacing}>
                    {dailyBudgetPacing}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label="Impression Tracking Method"
                id="demand-partner-impressionTrackingMethod"
                name="impressionTrackingMethod"
                data-cy="impressionTrackingMethod"
                type="select"
              >
                {impressionTrackingMethodValues.map(impressionTrackingMethod => (
                  <option value={impressionTrackingMethod} key={impressionTrackingMethod}>
                    {impressionTrackingMethod}
                  </option>
                ))}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/demand-partner" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default DemandPartnerUpdate;

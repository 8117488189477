import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './demand-app-approval-status.reducer';

export const DemandAppApprovalStatusDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const demandAppApprovalStatusEntity = useAppSelector(state => state.demandAppApprovalStatus.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="demandAppApprovalStatusDetailsHeading">Demand App Approval Status</h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">Id</span>
          </dt>
          <dd>{demandAppApprovalStatusEntity.id}</dd>
          <dt>
            <span id="demandPartnerId">Demand Partner Id</span>
          </dt>
          <dd>{demandAppApprovalStatusEntity.demandPartnerId}</dd>
          <dt>
            <span id="appBundle">App Bundle</span>
          </dt>
          <dd>{demandAppApprovalStatusEntity.appBundle}</dd>
          <dt>
            <span id="status">Status</span>
          </dt>
          <dd>{demandAppApprovalStatusEntity.status}</dd>
          <dt>
            <span id="lineInserted">Line Inserted</span>
          </dt>
          <dd>
            {demandAppApprovalStatusEntity.lineInserted ? (
              <TextFormat value={demandAppApprovalStatusEntity.lineInserted} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="dateUpdated">Date Updated</span>
          </dt>
          <dd>
            {demandAppApprovalStatusEntity.dateUpdated ? (
              <TextFormat value={demandAppApprovalStatusEntity.dateUpdated} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
        </dl>
        <Button tag={Link} to="/demand-app-approval-status" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/demand-app-approval-status/${demandAppApprovalStatusEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

export default DemandAppApprovalStatusDetail;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ==========================================================================
Development Ribbon
========================================================================== */
.ribbon {
  background-color: rgba(170, 0, 0, 0.5);
  left: -3.5em;
  transform: rotate(-45deg);
  overflow: hidden;
  position: absolute;
  top: 40px;
  white-space: nowrap;
  width: 15em;
  z-index: 99999;
  pointer-events: none;
  opacity: 0.75;
}
.ribbon a {
  color: #fff;
  display: block;
  font-weight: 400;
  margin: 1px 0;
  padding: 10px 50px;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 0 5px #444;
  pointer-events: none;
}

/* ==========================================================================
Navbar styles
========================================================================== */
.jh-navbar {
  background-color: #353d47;
  padding: 0.2em 1em;
}
.jh-navbar .profile-image {
  margin: -10px 0px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.jh-navbar .dropdown-item.active,
.jh-navbar .dropdown-item.active:focus,
.jh-navbar .dropdown-item.active:hover {
  background-color: #353d47;
}
.jh-navbar .dropdown-toggle::after {
  margin-left: 0.15em;
}
.jh-navbar ul.navbar-nav {
  padding: 0.5em;
}
.jh-navbar ul.navbar-nav .nav-item {
  margin-left: 1.5rem;
}
.jh-navbar a.nav-link {
  font-weight: 400;
}
.jh-navbar a.nav-link > span {
  margin-left: 5px;
}
.jh-navbar .jh-navbar-toggler {
  color: #ccc;
  font-size: 1.5em;
  padding: 10px;
}
.jh-navbar .jh-navbar-toggler:hover {
  color: #fff;
}

.navbar-brand {
  overflow: hidden;
}

.jh-navbar .navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-version {
  font-size: 0.65em;
  color: #bbb;
  padding: 0 0 0 10px;
}

.brand-logo:hover {
  text-decoration: none;
}
.brand-logo .brand-icon {
  height: 35px;
  width: auto;
  display: inline-block;
}
.brand-logo .brand-icon img {
  width: 45px;
}

.brand-title {
  font-size: 1.25rem;
  margin-left: 0.25rem;
  color: #fff;
}
.brand-title:hover {
  color: #cccccc;
  text-decoration: none;
}

.loading-bar {
  height: 3px;
  background-color: #009cd8;
  position: absolute;
  top: 0px;
  z-index: 1031;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/layout/header/header.scss"],"names":[],"mappings":"AAIA;;4EAAA;AAGA;EACE,sCAAA;EACA,YAAA;EAKA,yBAAA;EACA,gBAAA;EACA,kBAAA;EACA,SAAA;EACA,mBAAA;EACA,WAAA;EACA,cAAA;EACA,oBAAA;EACA,aAAA;AAHF;AAIE;EACE,WAAA;EACA,cAAA;EACA,gBAAA;EACA,aAAA;EACA,kBAAA;EACA,kBAAA;EACA,qBAAA;EACA,yBAAA;EACA,oBAAA;AAFJ;;AAMA;;4EAAA;AAGA;EACE,yBAAA;EACA,kBAAA;AAHF;AAIE;EACE,iBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;AAFJ;AAIE;;;EAGE,yBAAA;AAFJ;AAIE;EACE,mBAAA;AAFJ;AAIE;EACE,cAAA;AAFJ;AAGI;EACE,mBAAA;AADN;AAIE;EACE,gBAAA;AAFJ;AAGI;EACE,gBAAA;AADN;AAIE;EACE,WAAA;EACA,gBAAA;EACA,aAAA;AAFJ;AAGI;EACE,WAAA;AADN;;AAKA;EACE,gBAAA;AAFF;;AAKA;EACE,gBAAA;EACA,eAAA;AAFF;;AAKA;EACE,iBAAA;EACA,WAvFuB;EAwFvB,mBAAA;AAFF;;AAME;EACE,qBAAA;AAHJ;AAKE;EACE,YAAA;EACA,WAAA;EACA,qBAAA;AAHJ;AAII;EACE,WAAA;AAFN;;AAOA;EACE,kBAAA;EACA,oBAAA;EACA,WA7Ga;AAyGf;AAKE;EACE,cA7GiB;EA8GjB,qBAAA;AAHJ;;AAOA;EACE,WAAA;EACA,yBAAA;EACA,kBAAA;EACA,QAAA;EACA,aAAA;AAJF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

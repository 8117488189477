import dayjs from 'dayjs';
import { Status } from 'app/shared/model/enumerations/status.model';

export interface IDemandAppApprovalStatus {
  id?: number;
  demandPartnerId?: number | null;
  appBundle?: string | null;
  status?: keyof typeof Status | null;
  lineInserted?: dayjs.Dayjs | null;
  dateUpdated?: dayjs.Dayjs | null;
}

export const defaultValue: Readonly<IDemandAppApprovalStatus> = {};

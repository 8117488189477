export enum EndpointDatacenterLocation {
  US_EAST_OHIO = 'US_EAST_OHIO',

  US_EAST_N_VIRGINIA = 'US_EAST_N_VIRGINIA',

  US_WEST_N_CALIFORNIA = 'US_WEST_N_CALIFORNIA',

  US_WEST_OREGON = 'US_WEST_OREGON',

  AFRICA_CAPETOWN = 'AFRICA_CAPETOWN',

  ASIA_PACIFIC_HONGKONG = 'ASIA_PACIFIC_HONGKONG',

  ASIA_PACIFIC_HYDERABAD = 'ASIA_PACIFIC_HYDERABAD',

  ASIA_PACIFIC_MUMBAI = 'ASIA_PACIFIC_MUMBAI',

  ASIA_PACIFIC_OSAKA = 'ASIA_PACIFIC_OSAKA',

  ASIA_PACIFIC_SEOUL = 'ASIA_PACIFIC_SEOUL',

  ASIA_PACIFIC_SINGAPORE = 'ASIA_PACIFIC_SINGAPORE',

  ASIA_PACIFIC_SYDNEY = 'ASIA_PACIFIC_SYDNEY',

  ASIA_PACIFIC_TOKYO = 'ASIA_PACIFIC_TOKYO',

  CANADA_CENTRAL = 'CANADA_CENTRAL',

  CANADA_WEST_CALGARY = 'CANADA_WEST_CALGARY',

  EUROPE_FRANKFURT = 'EUROPE_FRANKFURT',

  EUROPE_IRELAND = 'EUROPE_IRELAND',

  EUROPE_LONDON = 'EUROPE_LONDON',

  EUROPE_MILAN = 'EUROPE_MILAN',

  EUROPE_PARIS = 'EUROPE_PARIS',

  EUROPE_SPAIN = 'EUROPE_SPAIN',

  EUROPE_STOCKHOLM = 'EUROPE_STOCKHOLM',

  EUROPE_ZURICH = 'EUROPE_ZURICH',

  ISRAEL_TEL_AVIV = 'ISRAEL_TEL_AVIV',

  MIDDLE_EAST_BAHRAIN = 'MIDDLE_EAST_BAHRAIN',

  MIDDLE_EAST_UAE = 'MIDDLE_EAST_UAE',

  SOUTH_AMERICA_SAO_PAULO = 'SOUTH_AMERICA_SAO_PAULO',

  AWS_GOVCLOUD_US_EAST = 'AWS_GOVCLOUD_US_EAST',

  AWS_GOVCLOUD_US_WEST = 'AWS_GOVCLOUD_US_WEST',
}

import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ImpressionTrackingMethod } from 'app/shared/model/enumerations/impression-tracking-method.model';
import { createEntity, getEntity, reset, updateEntity } from './supply-partner.reducer';

export const SupplyPartnerUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const supplyPartnerEntity = useAppSelector(state => state.supplyPartner.entity);
  const loading = useAppSelector(state => state.supplyPartner.loading);
  const updating = useAppSelector(state => state.supplyPartner.updating);
  const updateSuccess = useAppSelector(state => state.supplyPartner.updateSuccess);
  const impressionTrackingMethodValues = Object.keys(ImpressionTrackingMethod);

  const handleClose = () => {
    navigate(`/supply-partner${location.search}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }

    const entity = {
      ...supplyPartnerEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          impressionTrackingMethod: 'BURL',
          ...supplyPartnerEntity,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="bidfilter3UiApp.supplyPartner.home.createOrEditLabel" data-cy="SupplyPartnerCreateUpdateHeading">
            Create or edit a Supply Partner
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField name="id" required readOnly id="supply-partner-id" label="ID" validate={{ required: true }} />
              ) : null}
              <ValidatedField
                label="Supply Partner Name"
                id="supply-partner-supplyPartnerName"
                name="supplyPartnerName"
                data-cy="supplyPartnerName"
                type="text"
              />
              <ValidatedField label="Domain" id="supply-partner-domain" name="domain" data-cy="domain" type="text" />
              <ValidatedField label="Contact Name" id="supply-partner-contactName" name="contactName" data-cy="contactName" type="text" />
              <ValidatedField
                label="Contact Email"
                id="supply-partner-contactEmail"
                name="contactEmail"
                data-cy="contactEmail"
                type="text"
              />
              <ValidatedField
                label="Supply Partner Discription"
                id="supply-partner-supplyPartnerDiscription"
                name="supplyPartnerDiscription"
                data-cy="supplyPartnerDiscription"
                type="text"
              />
              <ValidatedField
                label="Public Name For Supply Partner"
                id="supply-partner-publicNameForSupplyPartner"
                name="publicNameForSupplyPartner"
                data-cy="publicNameForSupplyPartner"
                type="text"
              />
              <ValidatedField label="Seller Type" id="supply-partner-sellerType" name="sellerType" data-cy="sellerType" type="text" />
              <ValidatedField
                label="Is Confidential"
                id="supply-partner-isConfidential"
                name="isConfidential"
                data-cy="isConfidential"
                check
                type="checkbox"
              />
              <ValidatedField
                label="Impression Tracking Method"
                id="supply-partner-impressionTrackingMethod"
                name="impressionTrackingMethod"
                data-cy="impressionTrackingMethod"
                type="select"
              >
                {impressionTrackingMethodValues.map(impressionTrackingMethod => (
                  <option value={impressionTrackingMethod} key={impressionTrackingMethod}>
                    {impressionTrackingMethod}
                  </option>
                ))}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/supply-partner" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default SupplyPartnerUpdate;

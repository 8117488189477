import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ContentTaxonomy from './content-taxonomy';
import ContentTaxonomyDetail from './content-taxonomy-detail';
import ContentTaxonomyUpdate from './content-taxonomy-update';
import ContentTaxonomyDeleteDialog from './content-taxonomy-delete-dialog';

const ContentTaxonomyRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ContentTaxonomy />} />
    <Route path="new" element={<ContentTaxonomyUpdate />} />
    <Route path=":id">
      <Route index element={<ContentTaxonomyDetail />} />
      <Route path="edit" element={<ContentTaxonomyUpdate />} />
      <Route path="delete" element={<ContentTaxonomyDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ContentTaxonomyRoutes;

import supplyPartner from 'app/entities/supply-partner/supply-partner.reducer';
import demandPartner from 'app/entities/demand-partner/demand-partner.reducer';
import supply from 'app/entities/supply/supply.reducer';
import demand from 'app/entities/demand/demand.reducer';
import supplyToDemandFilter from 'app/entities/supply-to-demand-filter/supply-to-demand-filter.reducer';
import contentTaxonomy from 'app/entities/content-taxonomy/content-taxonomy.reducer';
import timeZone from 'app/entities/time-zone/time-zone.reducer';
import uiReportFilter from 'app/entities/ui-report-filter/ui-report-filter.reducer';
import userAccount from 'app/entities/user-account/user-account.reducer';
import scheduleReport from 'app/entities/schedule-report/schedule-report.reducer';
import demandAppApprovalStatus from 'app/entities/demand-app-approval-status/demand-app-approval-status.reducer';
import notification from 'app/entities/notification/notification.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  supplyPartner,
  demandPartner,
  supply,
  demand,
  supplyToDemandFilter,
  contentTaxonomy,
  timeZone,
  uiReportFilter,
  userAccount,
  scheduleReport,
  demandAppApprovalStatus,
  notification,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
